body,
html {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.login_body {
  width: 100%;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/login/login_bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
}

.login_main {
  margin-top: 0px;
  width: 710px;
  max-width: 98%;
}

.login_logo {
  margin-top: 17px;
  text-align: center;
}

.login_logo img {
  width: 100%;
  height: 100%;
  /* margin: -16px auto;
    display: block */
}

.login_form {
  background-color: #044875;
  border-radius: 20px;
  /* text-align: center; */
  margin: 0 auto;
  margin-top: 30px;
  width: auto;
  /* height: 50rem; */
  box-shadow: -1px 0 4px 0 #01e7fd, 3px 4px 8px 0 #033251 inset;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/login/form_background.webp);
  background-repeat: no-repeat;
  padding: 2rem;
  position: relative;
}
.email_cls .mat-mdc-form-field-error {
  text-align: unset !important;
}

.login_form .form_wrp {
  /* padding: 0 13%;
    padding-top: 40px */
  padding: 50px 80px 40px 80px;
}

.login_form p {
  font-size: 28px;
  color: #fff;
  text-transform: uppercase;
  line-height: 35px;
  text-align: center;
  margin: 0;
  margin-bottom: 25px;
}

.login_form .login-full-width {
  display: block;
  width: 100%;
  border: none;
  border-radius: 10px;
  /* margin-top: 38px; */
  margin-top: 25px;
  box-shadow: 0 4px 8px 0 #0a3f5a;
  font-size: 22px;
}

.email_cls {
  position: relative;
}

.email_cls .mdc-text-field {
  border-radius: 8px;
  padding-right: 43px;
}

.email_cls .form_wrp-field {
  font-size: 17px !important;
}

.pass_cls .form_wrp-field {
  font-size: 17px !important;
}

.pass_cls .mdc-text-field {
  border-radius: 8px;
}

.submit {
  font-size: 30px !important;
  width: 50%;
  border-radius: 35px;
  font-weight: 600;
  background: linear-gradient(
    180deg,
    rgba(255, 126, 30, 1) 20%,
    rgba(250, 93, 63, 1) 74%,
    rgba(246, 67, 88, 1) 100%
  );
  border: 6px solid #6d92a8;
  color: #fff;
  /* margin-top: 30px; */
  display: block;
  cursor: pointer;
  padding: 7px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.loader_wrp {
  width: calc(100% - 30px);
  margin-top: 20px !important;
  margin: 0 auto;
}
.new_loder_wrapper {
  position: fixed !important;
  margin-top: 0 !important;
}

.login_form h1 {
  font-size: 20px;
  color: #fff;
  margin: 0 auto;
  margin-top: 19px;
  cursor: pointer;
  width: fit-content;
}

a {
  color: inherit;
  text-decoration: none;
  font-weight: 500;
}

.email_cls .iconwrapper {
  position: absolute;
  z-index: 99;
  right: 12px;
  top: 17px;
}

.email_cls .iconwrapper i {
  font-size: 25px;
}

.forget_form {
  background-color: #044875;
  border-radius: 20px;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  margin-top: 115px;
  width: 83%;
  box-shadow: -1px 0 4px 0 #01e7fd, 3px 4px 8px 0 #033251 inset;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/login/form_background.webp);
  background-repeat: no-repeat;
}

.forget_form h1 {
  font-size: 20px;
  color: #fff;
  margin: 20px auto;
  cursor: pointer;
  width: fit-content;
}

.forget_form .form_wrp {
  padding: 0 13%;
  padding-top: 40px;
}

.forget_form p {
  font-size: 28px;
  color: #fff;
  text-transform: uppercase;
  line-height: 35px;
}

.forget_form .login-full-width {
  display: block;
  width: 100%;
  border: none;
  border-radius: 10px;
  margin-top: 30px;
  box-shadow: 0 4px 8px 0 #0a3f5a;
  font-size: 22px;
}

.email_cls {
  position: relative;
}

.error {
  color: #ff7e1e;
  display: block;
  text-align: left;
  padding-left: 5px;
  word-break: break-word;
}

.reset_form {
  background-color: #044875;
  border-radius: 20px;
  text-align: center;
  margin: 0 auto;
  margin-top: 115px;
  /* width: 705px; */
  width: 95%;
  box-shadow: -1px 0 4px 0 #01e7fd, 3px 4px 8px 0 #033251 inset;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/beto_paredes_nest/login/form_background.webp);
  background-repeat: no-repeat;
}

.reset_form h1 {
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  color: #fff;
  margin: 0;
  margin-top: 17px;
  cursor: unset;
  width: -moz-fit-content;
  width: fit-content;
}

.reset_form .form_wrp {
  padding: 0 13%;
  padding-top: 20px;
}

.reset_form p {
  font-size: 28px;
  color: #fff;
  text-transform: uppercase;
  line-height: 35px;
  margin-top: 5px;
}

.reset_form h4,
.forget_form h4 {
  color: #fff;
  padding: 10px;
  background: #0187df;
  width: 90%;
  margin: 16px auto;
}

.reset_form .login-full-width {
  display: block;
  width: 100%;
  border: none;
  border-radius: 10px;
  margin-top: 9px;
  box-shadow: 0 4px 8px 0 #0a3f5a;
  font-size: 19px;
}

.email_cls {
  position: relative;
  flex: 1 0 35%;
}
/* .email_wrpper {
    position: relative;
    flex: 1 0 28% !important;
    padding-left: 75px !important;
} */
.unique_name_modal .body_wrp .sub_header p,
.unique_name_modal .body_wrp .context li {
  word-break: break-word;
}

.reset_submit {
  font-size: 30px !important;
  width: 190px;
  border-radius: 35px;
  font-weight: 600;
  background: linear-gradient(
    180deg,
    rgba(255, 126, 30, 1) 20%,
    rgba(250, 93, 63, 1) 74%,
    rgba(246, 67, 88, 1) 100%
  );
  border: 6px solid #6d92a8;
  color: #fff;
  margin-top: 30px;
  cursor: pointer;
  padding: 7px;
  margin-right: 14px;
  margin-left: 14px;
  margin-bottom: 30px;
}

.login_form_shasow {
  height: 50px;
  width: 100%;
}

.login_form_shasow img {
  width: 100%;
  display: block;
  height: 100%;
}

.primary_delete {
  top: 40px;
}
.forothes {
  top: 0 !important;
  border-radius: 0px 6px 0px 6px !important;
}

@media screen and (max-width: 1199px) {
  .login_form {
    /* margin: 0 10%;
        margin-top: 96px; */
    margin: 0 10px;
    margin-top: 55px;
  }

  .login_form .form_wrp {
    /* padding: 15px 62px 40px 60px; */
    padding: 15px 40px 15px 40px;
  }

  .login_logo img {
    /* width: 60%; */
    width: 75%;
    height: 100%;
  }
}

@media screen and (max-width: 990px) {
  .login_form {
    /* margin: 0 10%;
        margin-top: 31px;
        width: 74%; */
    height: 36rem;
  }

  .login_form .form_wrp {
    padding: 11px 41px 9px 41px;
  }

  a {
    font-size: 14px;
  }

  .submit {
    font-size: 25px !important;
    width: 44%;
    border-radius: 35px;
    font-weight: 600;
    background: linear-gradient(
      180deg,
      rgba(255, 126, 30, 1) 20%,
      rgba(250, 93, 63, 1) 74%,
      rgba(246, 67, 88, 1) 100%
    );
    border: 6px solid #6d92a8;
    color: #fff;
    cursor: pointer;
    padding: 6px;
    margin-bottom: 0px;
  }

  .login_form h1 {
    font-size: 21px;
    color: #fff;
    margin: 0 auto;
    margin-top: 16px;
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
  }

  .login_form p {
    font-size: 19px;
    color: #fff;
    text-transform: uppercase;
    line-height: 25px;
  }
}

@media screen and (max-width: 890px) {
  .email_wrpper {
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .login_logo img {
    width: 65%;
    height: 100%;
  }
}

@media screen and (max-width: 499px) {
  .login_form {
    /* margin: 0 3%; */
    margin-top: 10%;
    padding: 1.5rem;
    height: 35rem;
  }

  .login_logo {
    text-align: center;
    /* width: 60%; */
    margin: 0 auto;
    margin-top: 10%;
  }
  .login_logo img {
    width: 90%;
    height: 100%;
  }

  .login_form .form_wrp {
    padding: 12px 12px;
  }

  .login_form p {
    line-height: 21px;
    font-size: 18px;
  }
  .login_body {
    background-size: cover;
    background-position: center;
  }
  .forget_form p {
    font-size: 21px;
    line-height: 25px;
  }
  .forget_form .form_wrp {
    padding: 0 5%;
    padding-top: 20px;
  }
  .forget_form {
    margin-top: 10%;
  }
  .forget_form h4 {
    width: 90%;
    margin: 16px auto;
  }
  .reset_form .form_wrp {
    padding: 0 5%;
    padding-top: 20px;
  }
}
@media screen and (max-width: 475px) {
  .reset_submit {
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 375px) {
  .login_logo img {
    width: 85%;
    height: 100%;
  }
  .submit {
    font-size: 18px !important;
    line-height: 23px;
    margin-bottom: 0px;
    width: 52%;
  }
  .login_form p {
    line-height: 20px;
    font-size: 16px;
  }
}

@media screen and (max-width: 360px) {
  .login_form .login-full-width {
    margin-top: 25px;
  }

  .email_cls .iconwrapper {
    top: 19px;
    right: 8px;
  }

  .email_cls .iconwrapper i {
    font-size: 21px;
    font-size: 21px;
    background-color: #fff;
    width: 30px;
  }

  .error {
    font-size: 12px;
  }

  a {
    font-size: 16px;
  }

  .submit {
    font-size: 18px !important;
    line-height: 23px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 330px) {
  .login_logo img {
    height: 100%;
  }

  .login_form .form_wrp {
    padding: 5px;
  }

  .login_form {
    /* margin: 0 7%; */
    margin-top: 10%;
  }

  /* .email_cls . */
}

/* .preview_modal .ng-star-inserted{
    overflow-y: scroll;
    max-height: 70vh;
} */

/* .container .list_main_wrp  .mat-mdc-chip{
    bottom: 31px;

} */
.list_main_wrp .mat-mdc-chip,
.editinfo .mat-mdc-chip {
  top: -28px;
  left: -3px;
}

.main_wrp .submited_wrp {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
}

.header_wrp {
  background-color: #f76218;
  text-align: center;
}

.sub_wrpper .header_wrp h1 {
  color: #fff;
  font-size: 31px;
}

.sub_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.sub_header p {
  font-size: 18px;
  text-transform: lowercase;
  margin: 15px;
  margin-left: 0;
}

.sub_wrpper h1 {
  font-size: 20px;
  line-height: 29px;
  font-weight: 500;
  /* margin-top: 15px; */
  padding: 10px 0;
  color: red;
}

.sub_wrpper h2 {
  font-size: 15px;
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 0px;
}

.sub_wrpper h3 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: red;
  margin-top: 11px;
}

.context {
  margin-left: 20px;
}

.context li {
  font-size: 18px;
  line-height: 24px;
  text-transform: lowercase;
}

/* .main_wrp .mat-mdc-dialog-container .mdc-dialog__surface{padding:0px !important;} */
.submited {
  font-size: 22px !important;
  width: 24%;
  border-radius: 13px;
  font-weight: 500;
  background: linear-gradient(
    180deg,
    rgba(255, 126, 30, 1) 20%,
    rgba(250, 93, 63, 1) 74%,
    rgba(246, 67, 88, 1) 100%
  );
  color: #fff;
  cursor: pointer;
  padding: 7px;
  margin-bottom: 7px;
}

@media screen and (max-width: 499px) {
  .unique_name_modal .submited {
    width: 40%;
  }
}

.body_wrp {
  padding: 13px;
}

.button_wrp {
  background: var(--orange-gradient);
  color: #fff;
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0 auto;
  min-width: 150px;
  padding: 14px 10px;
  text-transform: inherit !important;
  border-radius: 9px;
  line-height: 22px;
  letter-spacing: 0.7px;
  cursor: pointer;
}

/* //////////////////////Add Campaign modal css////////////// */
.camp-modalbox {
  height: auto !important;
  padding: 15px !important;
  width: auto;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 70vh;

  /* height: 400px;
    margin: 14px 14px !important;
    min-width: 419px;
    overflow: hidden;
    overflow-y: auto;
    padding: 7px !important; */
}

.camp-modalbox h1 {
  background-color: #b5c1bb;
  padding: 12px;
  margin-bottom: 14px;
  font-size: 25px;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 7px;
  text-align: center;
}

.camp-modalbox .addcampmodal .mat-mdc-form-field {
  width: 100%;
  margin-bottom: 14px;
  margin-top: 7px;
  text-transform: none;
}

.camp-modalbox
  .addcampmodal
  .form_field_wrappercategory_name
  .mat-mdc-text-field-wrapper {
  height: 50px;
}

.camp-modalbox
  .addcampmodal
  .form_field_wrapperdescription
  .mat-mdc-text-field-wrapper {
  height: 100px;
}

.camp-modalbox .addcampmodal .mat-mdc-checkbox {
  margin: 5px auto;
}

.camp-modalbox .addcampmodal .button {
  margin: 13px 7px;
  padding: 20px;
}

.camp-modalbox .addcampmodal .button .mdc-button__label {
  font-size: 18px;
}

.camp-modalbox .addcampmodal .mdc-label {
  font-size: 19px;
}

.showmodal_wrp {
  padding: 7px;
  /* width: 824px; */
}

.all_section {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-items: center;
}

.showmodal_wrp h1 {
  width: 98.7%;
  display: block;
  text-align: center;
  background-color: #b5c1bb;
}

.mainmodal_wrp {
  display: table;
  padding: 3px;
  /* width: 25%; */
  /* flex: 1 0 32.7%; */
}
.mainmodal_wrp_img {
  padding: 10px;
  background: #fff;
  width: 249px;
  margin-right: 3px;
  margin-bottom: 6px;
  margin-left: 3px;
}

.mainmodal_wrp_img img {
  width: 100%;
  height: 100%;
  display: block;
  /* padding-bottom: 5px; */
}

.mainmodal_wrp_img:hover {
  cursor: pointer;
  background: #2b0303;
}

.add-file-modal {
  height: auto !important;
  padding: 4px !important;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 71vh;
}

.add-file-modal .modal-image-wrapper .inner_wrp {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.add-file-modal .modal-image-wrapper .inner_wrp p {
  margin-top: 24px;
  margin-left: 11px;
}

.add-file-modal .modal-image-wrapper .header4piechrt {
  margin-top: 10px;
  text-align: center;
}

.add-file-modal .modal-image-wrapper .header4piechrt h1 {
  background: #ffdcb5;
  border-radius: 4px;
  margin: 0 auto;
  padding: 10px;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 23px;
  line-height: 29px;
  font-weight: 500;
}

/* ------------------------------deep----------------------------------- */

.email_cls .valid {
  color: green;
}

.input_lodaer .mdc-circular-progress__determinate-container {
  width: 50px;
  height: 50px;
}

.media_cls .actionbtndiv .mat-mdc-chip {
  bottom: 0;
}

.media_cls .imagecardcls {
  height: 400px;
  margin: auto;
  width: 400px;
}

.media_cls .imagecardcls img {
  /* object-fit: cover;
    height: 100%; */
  /* width: 100%; */
  margin-top: 15px;
}

.filesid {
  width: 450px;
  display: block !important;
  max-width: 90%;
}

.media_cls .imagedivcls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divimagecardcls {
  width: 100%;
}

.media_cls .example-card {
  height: auto;
  width: 100% !important;
}

.media_cls .ngx-ic-overlay {
  display: block;
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  margin-left: 0 !important;
}

.media_cls .cropimagesdiv {
  width: 48% !important;
}

/* .media_cls .mat-mdc-form-field .mat-mdc-chip {
    position: absolute;
    margin-left: -1px !important;
} */
.togglesearchcls .searchbar mat-form-field label[ng-reflect-floating="true"] {
  display: none;
}

.cmn_width {
  width: 100%;
  margin: 0 auto;
}

.new_Wraper_share .marketing-sowrapper .sb-facebook {
  background-color: #4267b2;
}

.new_Wraper_share .marketing-sowrapper .sb-twitter {
  background-color: #00acee;
}

.new_Wraper_share .marketing-sowrapper .sb-linkedin {
  background-color: #006fa6;
}

@media screen and (max-width: 1850px) {
  .cmn_width {
    width: 100%;
  }
}

.rep_banner_main_wrp {
  background-color: #fff;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.rep_banner_sub_wrp {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/beto_paredes_nest/rep_banner/rep_banner_2bg.webp),
    url(https://all-frontend-assets.s3.amazonaws.com/beto_paredes_nest/rep_banner/rep_banner_1bg.webp);
  background-repeat: no-repeat;
  background-position: right -26px bottom, left -106px center;
  background-size: auto 100%, cover;
  padding: 20px;
  position: relative;
}

.rep_banner_sub_sec_wrp {
  width: 90%;
  position: relative;
}

.rep_banner_sub_sec_wrp h1 {
  font-size: 38px;
  line-height: 47px;
  font-weight: 700;
  color: #fff;
  text-shadow: 0 3px 12px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  margin-bottom: 10px;
}

.rep_banner_sub_sec_wrp h1 span {
  color: #eef60d;
}

.rep_banner_sub_sec_wrp p {
  font-size: 29px;
  line-height: 35px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 3px 12px rgba(0, 0, 0, 0.4);
}

.rep_banner_sub_sec_wrp p span {
  color: #eef60d;
}

.rep_bnr_btn {
  padding: 10px 20px;
  border: none;
  border-radius: 7px;
  background: linear-gradient(
    180deg,
    rgba(0, 204, 255, 1) 0%,
    rgba(13, 129, 213, 1) 100%
  );
  color: #fff;
  font-size: 23px;
  line-height: 29px;
  font-weight: 500;
  position: absolute;
  right: 16%;
  bottom: 20px;
  cursor: pointer;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.rep_bnr_btn:active {
    /* box-shadow: rgba(0, 0, 0, 0.24) -6px 8px 12px;
    transition: all .2s; */
  box-shadow: 0 0 10px #d07c5a;
  transition-duration: 0.05s;
}

@media screen and (max-width: 1899px) {
  .rep_bnr_btn {
    right: 12%;
  }
}

@media screen and (max-width: 1850px) {
  /* .rep_bnr_btn {
        right: 265px;
    } */

  /* .rep_banner_sub_sec_wrp {
        width: 79%;
    } */
}

@media screen and (max-width: 1830px) {
  .rep_banner_sub_sec_wrp h1 {
    font-size: 40px;
    line-height: 46px;
  }

  .rep_banner_sub_sec_wrp p {
    font-size: 25px;
    line-height: 31px;
  }
  /* 
    .rep_bnr_btn {
        top: 513px;
    } */
}

@media screen and (max-width: 1650px) {
  /* .rep_banner_sub_sec_wrp {
        width: 74%;
    } */

  .rep_banner_sub_sec_wrp h1 {
    font-size: 36px;
    line-height: 42px;
  }

  .rep_banner_sub_sec_wrp p {
    font-size: 22px;
    line-height: 28px;
  }

  /* .rep_bnr_btn {
        top: 560px;
        right: 339px;
    } */
}

@media screen and (max-width: 1515px) {
  /* .rep_bnr_btn {
        right: 240px;
    } */

  .rep_banner_sub_sec_wrp h1 {
    font-size: 30px;
    line-height: 36px;
  }

  .rep_banner_sub_sec_wrp p {
    font-size: 20px;
    line-height: 26px;
  }

  /* .rep_bnr_btn {
        top: 396px;
        right: 82px;
        
    } */
}
@media screen and (max-width: 1410px) {
  /* .rep_bnr_btn {
        right: 300px;
    } */
}

@media screen and (max-width: 1410px) {
  .rep_banner_sub_wrp {
    background-position: right -65px bottom, left -106px center;
  }
}

@media screen and (max-width: 1380px) {
  .rep_bnr_btn {
    font-size: 20px;
    line-height: 26px;
    padding: 10px 20px;
    /* right: 10%; */
  }
  .rep_banner_sub_sec_wrp {
    width: 75%;
  }
  /* .rep_banner_sub_sec_wrp {
        width: 80%;
    } */
}
/* @media screen and (max-width: 1325px) {
    .rep_bnr_btn {
        right: 185px;
    }
} */

@media screen and (max-width: 1290px) {
  .rep_banner_sub_wrp {
    background-position: right -90px bottom, left -106px center;
  }

  .rep_bnr_btn {
    right: 14%;
  }
}

/* @media screen and (max-width: 1250px) {
    .rep_banner_sub_wrp {
        background-position: right -90px bottom, left -106px center;
    }
} */

@media screen and (max-width: 1199px) {
  .rep_banner_sub_sec_wrp {
    width: 100%;
  }

  .rep_bnr_btn {
    position: unset;
  }

  .rep_banner_sub_sec_wrp p {
    margin-bottom: 10px;
  }

  .rep_banner_sub_wrp {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/beto_paredes_nest/rep_banner/rep_banner_1bg.webp);
    background-position: center;
    background-size: cover;
  }

  .rep_banner_sub_sec_wrp h1 br {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .rep_banner_sub_sec_wrp h1 {
    font-size: 25px;
    line-height: 31px;
  }

  .rep_banner_sub_sec_wrp p {
    font-size: 18px;
    line-height: 24px;
  }
}

.main_wrp .text_wrp .textfirld_wrp .mat-mdc-text-field-wrapper {
  background-color: #fff;
}

.main_wrp .mdc-text-field--no-label {
  text-transform: none;
  /* padding-top: 15px !important;
    padding-bottom: 16px !important; */
}

.addLead .grp1 {
  display: flex;
  flex-wrap: wrap;
}

.addLead .grp2 {
  display: block;
  width: 100%;
}

.addLead .grp3 {
  display: block;
  width: 100%;
}

.add-file-modal .inner_sub_wrp .count_button_wrp {
  padding: 6px;
  border: none;
  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgba(0, 204, 255, 1) 0%,
    rgba(13, 129, 213, 1) 100%
  );
  color: #fff;
  font-size: 13px;
  line-height: 26px;
  font-weight: 500;
  display: block;
  margin: 11px;
  cursor: pointer;
}

.add-file-modal .inner_sub_wrp .colorcode {
  padding: 6px;
  border: none;
  border-radius: 4px;
  background: #f67d24;
  color: #fff;
  font-size: 13px;
  line-height: 26px;
  font-weight: 500;
  display: block;
  margin: 11px;
  cursor: pointer;
}

.add-file-modal .inner_sub_wrp {
  display: flex;
  justify-content: center;
  margin: 0 0 10px;
}

/* 
.add-file-modal .inner_sub_wrp .converstion_button_wrp {
    padding: 6px;
    border: none;
    border-radius: 4px;
    background: linear-gradient(180deg, rgba(0, 204, 255, 1) 0%, rgba(13, 129, 213, 1) 100%);
    color: #fff;
    font-size: 13px;
    line-height: 26px;
    font-weight: 500;
    display: block;
    margin: 11px;
    cursor: pointer;
} */
.add-file-modal .modal-image-wrapper .inner_wrp .main_button_wrp {
  padding: 9px 22px;
  border: none;
  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgba(0, 204, 255, 1) 0%,
    rgba(13, 129, 213, 1) 100%
  );
  color: #fff;
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  display: block;
  margin: 11px;
  cursor: pointer;
}

.add-file-modal .modal-image-wrapper .count_value_wrp {
  display: flex;
  justify-content: center;
  margin: 12px 19px;
}

.add-file-modal .modal-image-wrapper .count_value_wrp h4 {
  font-weight: 600;
  padding: 5px;
  text-transform: uppercase;
}

.is_qr .mat-mdc-dialog-surface {
  width: 400px !important;
  max-width: 90% !important;
}

.is_qr h1 {
  width: 98.7%;
  display: block;
  text-align: center;
  background-color: #b5c1bb;
}

.is_qr .modal-imgwrapper .qrcode {
  padding-bottom: 16px;
}

.norecord {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  min-height: 41vh;
}

.norecord p {
  color: #fb3332;
  margin: 0 !important;
  font-size: 2rem;
  font-weight: 500;
}

.count_modal_wrp {
  width: min(100%, 1000px) !important;
  max-width: inherit !important;
}

.showcamp_modal {
  width: min(90%, 900px) !important;
  max-width: inherit !important;
}

/* input[type=hidden]{
    display: none;
}
.form_field_wrapperrep_id{
    display: none;
} */

/* .footer_main_wrp .mat-mdc-form-field-flex{
    display: inline-flex;
    align-items: baseline;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    top: -24px;
} */

/* .footer_main_wrp .footer_sub_wrp .signature_wrpper .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 12px;
    padding-bottom: 6px;
} */

.footer_main_wrp
  .footer_sub_wrp
  .signature_wrpper
  .mat-mdc-text-field-wrapper
  .mat-mdc-form-field-infix {
  min-height: unset !important;
  padding-top: 8px;
  padding-bottom: 8px;
}

.choose_products mat-form-field {
  width: 100%;
  margin-bottom: 10px;
}
.choose_products .form-element {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.choose_products .form-element button {
  background: var(--orange-gradient);
  font-size: 20px;
  letter-spacing: 0;
  padding: 26px 40px !important;
  line-height: 22px;
  border-radius: 40px;
  color: #fff;
  margin: 0 5px;
  display: flex;
  margin-top: 15px;
  cursor: pointer;
  text-transform: uppercase;
}

.choose_products .mat-mdc-chip {
  top: -33px;
  left: -3px;
}
.feather_com .mdc-text-field__input {
  color: transparent !important;
}

/* ==========================Presentation Center======================= */
.video_center_wrapper {
  display: flex;
  justify-content: space-between;
  width: 96% !important;
  margin: 0 auto;
}
.category_name {
  background: #4dd4ff;
  padding: 5px 10px;
  border-radius: 5px 10px 5px 10px;
  margin-right: 7px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.category_name h3 {
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 0;
}
.video_center_left {
  width: 70%;
  /* border: 4px solid #000000; */
  /* padding: 10px; */
  border-radius: 10px;
}
.video_center_right {
  width: 29%;
  /* overflow-y: scroll; */
}
.video_center_rightSub {
  max-height: 80vh;
  /* overflow-y: scroll; */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 96%;
  margin: 0 auto;
}
.video_main {
  width: 96%;
  margin: 0 auto;
}
.video_name {
  background: #0071bc;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 5px !important;
  margin-bottom: 0 !important;
  color: #ffffff;
  border-radius: 5px;
}
.video_selected {
  background: #4dd4ff;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 25px;
  line-height: 26px;
  text-transform: capitalize;
  padding: 15px;
  border-radius: 10px 10px 0 0;
}
.video_desc {
  background: #ffffff;
  font-size: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  font-size: 18px;
  line-height: 25px;
  margin: 10px 0 10px 0px;
}
.buttons_parent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 5px 0;
}
.button_wrpr {
  font-size: 14px;
  padding: 6px;
  display: block;
  border-radius: 10px;
  cursor: pointer;
  color: #000000;
  text-transform: capitalize;
  margin: 5px 5px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.button_wrpr span {
  color: rgb(6, 95, 212);
  margin-right: 4px;
}
.button_wrpr .mat-ligature-font {
  margin-left: 4px;
}
.button_wrpr.activeButton .mat-ligature-font {
  color: #ffffff !important;
}
.activeCatagory {
  background: #a9d30b;
}
.video_name {
  cursor: pointer;
}
.activevideo .video_name {
  background: linear-gradient(
    180deg,
    rgba(255, 128, 28, 1) 0%,
    rgba(246, 65, 91, 1) 100%
  );
}
.activeButton {
  background: #a9d30b;
  color: #ffffff;
}
.video_mainWrpr {
  border-radius: 0 0 10px 10px;
}
.video_mainWrpr video {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 0 0 10px 10px;
}
.followUpform .mat-mdc-form-field-subscript-wrapper {
  display: block;
}
.pastfollowUp span:nth-child(1) {
  background-color: #63666b;
}
@media screen and (max-width: 1199px) {
  .video_center_wrapper {
    flex-direction: column-reverse;
  }
  .video_center_right,
  .video_center_left {
    width: 100%;
  }
}
@media screen and (max-width: 680px) {
  .buttons_parent {
    flex-direction: column;
  }
  .buttons_parent .button_sub {
    width: 100%;
  }
  .button_wrpr {
    width: 98%;
    justify-content: center;
  }
}

/* Deep  */
.addUrl_set_button button {
  margin: 5px;
  background: var(--orange-gradient);
  height: auto !important;
  font-size: 2.4rem;
  border-radius: 5px;
  color: #fff !important;
  cursor: pointer;
  box-shadow: none;
  padding: 1.2rem 2.5rem !important;
  border: 1px solid #ff7f00;
  text-shadow: 1px 1px 1px #874403;
  min-height: inherit !important;
  box-shadow: -4px -2px 9px #fefffe, 3px 3px 13px #586760;
  transition: all 0.1s;
}
/* .contacteditFormShow {
  display: flex;
  align-items: center;
  width: 100%;
} */
/* .contacteditFormShow .mat-mdc-form-field {
  width: 25%;
} */
.sendCOntractBtttonWrapp {
  display: block !important;
}
.contacteditFormShow form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.contacteditFormShow form .form_field_wrapper {
  flex: 1 0 30%;
  margin: 8px;
}
.contacteditFormShow form .form_field_wrapper .mat-mdc-form-field {
  width: 100%;
}
.contacteditFormShow .mdc-icon-button{
  background: none !important;
    height: auto !important;
    font-size:  none !important;
    border-radius:  none !important;
    color:  #000000 !important;
    /* cursor:  none !important; */
    box-shadow:  none !important;
    padding:  0 !important;
    border:  none !important;
    box-shadow:  none !important;
    transition:  none !important;
    display: block;
    width:  auto !important;
}
/* ////// contact css //////// */

.details_sec_wrpr{
  width: 100%; display: flex; justify-content: space-between;
}
.details_sec_wrpr .company_details_sec{
  margin-right: 10px;
}
.trwithBorder_contarct td {
  border-bottom: 1px solid #000000;
  vertical-align: top;
  padding: 7px 7px 7px 7px;
  border-top: 1px solid #000000;
  border-right: 1px solid #000000;
  border-left: 1px solid #000000;
}

.contacteditFormShow .submitbtnsection{
  width: 100%;

}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
